.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}






a {
  text-decoration: none !important;
}


.hometext{
  font-family: "Edu AU VIC WA NT Hand", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.container{
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
}



.bestberhamporeschool{
  background-color: #1fa7d8;
}

.textjustify{
  text-align: justify;
  text-justify: inter-word;
}

.englishmediumschool{
  background-color: #1E8FCE; /* Green */
  border: none;
  color: white;
  padding: 6px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
}


.blink { 
  animation: blink-animation 1s infinite; 
} 
  
@keyframes blink-animation { 
  0% { 
    opacity: 1; 
  } 
  50% { 
    opacity: 0; 
  } 
  100% { 
    opacity: 1; 
  } 
}

.backs{
  background-color: #1E8FCE;
  padding: 04px;
}



.btn-grad15 {
  background-image: linear-gradient(to right, #7bb5ee 0%, #217cb1  51%, #0b2758  100%);
  margin: 0px;
  padding: 9px 29px;
  font-size: 14px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 4px;
  display: block;
}

.btn-grad15:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.bestberhamporeschoolmis{
  background-color: #a3d3f5;
}

.payment{
  background-color: #1E8FCE; /* Green */
  border: none;
  color: white;
  padding: 6px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
}

.schoolberhampore{
  color: #1250bd;
}


.btn-grad30 {
  background-image: linear-gradient(to right, #A96A09 0%, #BA8616  51%, #7a5c01  100%);
  margin: 0px;
  padding: 9px 29px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 12px;
  display: block;
}

.btn-grad30:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}







* {box-sizing: border-box}

/* Container needed to position the overlay. Adjust the width as needed */
.hoverimage1 {
  position: relative;

}

/* Make the image to responsive */
.image2 {
  display: block;
  width: 100%;
  height: auto;
}

/* The overlay effect - lays on top of the container and over the image */
.overlay1 {
  position: absolute;
  bottom: 0;
  background: rgb(20, 20, 21);
  background: rgba(24, 25, 25, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
  border-radius: 0px;
}

/* When you mouse over the container, fade in the overlay title */
.hoverimage1:hover .overlay1 {
  opacity: 2;
}




.colorgallery{
background-color: #e0e8f7;
}



.btn-grad134 {
  background-image: linear-gradient(to right, #a36d11 0%, #006364  51%, #edd4e6  100%);
  margin: 0px;
  padding: 10px 29px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 6px;
  display: block;
}

.btn-grad134:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}



.btn-grad90 {
  background-image: linear-gradient(to right, #0b2c65 0%, #e5d6f3  51%, #153b43  100%);
  margin: 0px;
  padding: 7px 22px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 6px;
  display: block;
}

.btn-grad90:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}




* {box-sizing: border-box}

/* Container needed to position the overlay. Adjust the width as needed */
.hoverimage1 {
  position: relative;

}

/* Make the image to responsive */
.image2 {
  display: block;
  width: 100%;
  height: auto;
}

/* The overlay effect - lays on top of the container and over the image */
.overlay1 {
  position: absolute;
  bottom: 0;
  background: rgb(20, 20, 21);
  background: rgba(24, 25, 25, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
  border-radius: 0px;
}

/* When you mouse over the container, fade in the overlay title */
.hoverimage1:hover .overlay1 {
  opacity: 2;
}