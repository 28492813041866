
.missionl{
    margin-left: 463px;
    max-width: 26rem;
}
.colors{
    color:rgb(80, 77, 77);
}


 
.column{
    margin-left:20px;
}
.submite2{
    display: flex;
   margin-left: 110px; 
}
.g-btn{
    margin: 20px;
}