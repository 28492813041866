.exofficiocard {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 2px 1px 0 rgba(0,0,0,0.2);
    transition: 0.1s;
  }
  
  /* On mouse-over, add a deeper shadow */
  .exofficiocard:hover {
    box-shadow: 0 4px 32px 0 rgba(0,0,0,0.2);
  }
  
  /* Add some padding inside the card container */
  .containernew {
    padding: 2px 16px;
  }
  

  .morebutton{
    background-color: #1E8FCE; /* Green */
    border: none;
    color: white;
    padding: 6px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
  }

  .lifetimecard{
    background-color: #0814c3; /* Green */
    border: none;
    color: white;
    padding: 6px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 3px;
  }

  .homebutton{
    background-color: #D92927; /* Green */
    border: none;
    color: white;
    padding: 6px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 3px;
  }





/* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */
.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Style the icon bar links */
.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.6s ease;
  color: white;
  font-size: 20px;
}

/* Style the social media icons with color, if you want */
/* .icon-bar a:hover {
  background-color: #4e4848;
} */

.facebook {
  background: #108805;
  color: white;
}

.blue {
  background: #101f97;
  color: white;
}

.red {
  background: #af0f0f;
  color: white;
}

.red {
  background: #ed0000;
  color: rgb(255, 255, 255);
}

.yellow {
  background: yellow;

}

.buttonanthem{
  background-color: #0814c3; /* Green */
  border: none;
  color: white;
  padding: 7px 26px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 19px;
  border-radius: 3px;
}

.buttnnew{
  background-color: #897423; /* Green */
  border: none;
  color: white;
  padding: 7px 26px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 19px;
  border-radius: 3px;
}

.colortextoffice{
  color: #020a77;
}

.colordesignation{
  color: #8a4004;
}



.card {
 
  background: white;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}
.card img {
  width: 100%;
  height: 60%;
  object-fit: cover;
}
.card .content {
  padding: 15px;
  text-align: center;
}
.card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}